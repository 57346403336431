.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
html{
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ant-modal .ant-modal-content {
  padding: 10px 10px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.letter-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.0); /* Adjust the values for a stronger effect */
  display: inline-block;
}
/* opportunities page */




.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #00ff00; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin:0;
}

.ant-slider .ant-slider-handle::before {
  background-color: transparent;
}
.ant-slider .ant-slider-handle::after{
background-color: #00ff00;
}

.ant-slider .ant-slider-track{
  background-color: #E3E3E3;

}
.ant-slider-horizontal .ant-slider-rail {
  height: 5px;
}
.ant-slider-horizontal .ant-slider-track{
  height: 5px;
}
.ant-slider:hover .ant-slider-track{
  background-color: #E3E3E3;

}

.ant-slider .ant-slider-handle::after{
  box-shadow: none;
}
.icon{
  transition: 0.3s ease-in-out;
}
.main:hover .icon{
  color: #2272FF;
}
.ant-upload-wrapper .ant-upload-drag{
 background-color:  #E5EEFF;
}
.CircularProgressbar .CircularProgressbar-path{
  stroke: #00ff00;
  background:#2272FF;
  color: #00ff00;
}
.iteyOh{
  display: flex !important;
  justify-content: center;
 align-items: center;
}
.faZveH{
  display: flex !important;
  justify-content: start !important;
  
  flex-grow: 0;
}
.wrapper{
  
}
.container {
  background-color: rgb(207, 207, 207);
  height: 7px;
  border-radius: 10px;
  width: 100%;
}
.barCompleted {
  background-color: green;
  width: 15px;
  height: 7px;
  border-radius: 10px;
}
.label {
  font-size: none;
  visibility: hidden;
}